<div class="q-content">
  <div class="d-flex justify-content-between gap-15 flex-wrap mb-20">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
  </div>

  <div class="q-content-bg">
    <div class="d-flex gap-15 flex-wrap justify-content-between">
      <div class="d-flex gap-15 flex-wrap align-items-center">
        <app-common-header
          (triggerDataFromHeader)="getDataByHeaderData($event)"
          [showDateFilter]="false"
        ></app-common-header>
        <div>
          <app-common-separate-date-picker-header
            (onAnalyticsFilterChange)="onAnalyticsFilterChange($event)"
            (onAnalyticsSelectYearFilterChange)="getAllYearData()"
          ></app-common-separate-date-picker-header>
        </div>
      </div>
      <div class="d-flex gap-15 flex-wrap justify-content-end">
        <button kendoButton (click)="getAllYearData()">All Years</button>
        <button kendoButton (click)="onAllButtonClick()">Live</button>
      </div>
    </div>
    <hr />
    <div class="q-card border dtr-graph-container">
      <div *ngIf="showLoader">
        <div class="k-loading-panel">
          <div class="k-loading-panel-mask"></div>
          <div class="k-loading-panel-wrapper">
            <kendo-loader
              *ngIf="showLoader"
              type="infinite-spinner"
              size="large"
            >
            </kendo-loader>
            <div class="k-loading-panel-text">Loading...</div>
          </div>
        </div>
      </div>
      <div>
        <kendo-chart
          *ngIf="!isNoRecordFound"
          [categoryAxis]="categoryAxis"
          [pannable]="{ lock: 'y' }"
          [zoomable]="{ mousewheel: { lock: 'y' }, selection: { lock: 'y' } }"
          [seriesColors]="[
            '#ed6572',
            '#f9bc43',
            '#3a82f4',
            '#ed6572',
            '#f9bc43',
            '#3a82f4',
            '#2cac8d',
          ]"
        >
          <kendo-chart-title
            text="Multiple Categorical Axes"
          ></kendo-chart-title>

          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item
              [categories]="categories"
              [min]="0"
              [max]="roundedMaxNumber"
            ></kendo-chart-category-axis-item>
          </kendo-chart-category-axis>

          <kendo-chart-value-axis>
            <!-- [title]="{ text: 'Bar Values'}" -->
            <kendo-chart-value-axis-item
              [name]="'leftAxis'"
              [line]="{ visible: true }"
              [position]="'left'"
            ></kendo-chart-value-axis-item>
            <!-- [title]="{ text: 'Line Values'}" -->
            <kendo-chart-value-axis-item
              [name]="'rightAxis'"
              [line]="{ visible: true }"
              [position]="'right'"
            ></kendo-chart-value-axis-item>
          </kendo-chart-value-axis>

          <kendo-chart-legend position="bottom"></kendo-chart-legend>

          <kendo-chart-series>
            <ng-container *ngFor="let series of seriesData; let i = index">
              <kendo-chart-series-item
                [name]="series.name"
                [data]="lineAndBarChartData"
                [type]="series.type"
                [field]="series.field"
                [categoryField]="'date'"
                [stack]="false"
                [visible]="series.visible"
                [gap]="1"
                [axis]="series.axis"
              >
              </kendo-chart-series-item>
            </ng-container>
          </kendo-chart-series>

          <kendo-chart-tooltip [shared]="true">
            <ng-template kendoChartTooltipTemplate let-tooltip="tooltip">
              <div class="tooltip-container">
                <div *ngFor="let point of tooltip.points" class="tooltip-item">
                  <div class="tooltip-series-name">{{ point.series.name }}</div>
                  <div class="tooltip-value">{{ point.value }}</div>
                </div>
              </div>
            </ng-template>
          </kendo-chart-tooltip>
        </kendo-chart>
      </div>

      <div *ngIf="isNoRecordFound">
        <div
          *ngIf="isLineAndBarChartData"
          class="no-data-message d-flex align-items-center justify-content-center flex-column gap-20"
        >
          <img src="assets/images/no-data-bar-chart.png" alt="data" />
          No data available yet!!
        </div>
      </div>
    </div>
  </div>
</div>
