<div class="q-content">
  <div class="title">User Info</div>
  <div class="q-content-bg">
    <div class="mb-20 user-info-section">
      <form
        [formGroup]="accountForm"
        class="k-form k-form-md"
        autocomplete="off"
      >
        <div class="d-flex flex-wrap gap-15 mb-20">
          <kendo-formfield class="mt-0 wmax-280">
            <kendo-label
              [for]="firstName"
              text="First Name"
              labelCssClass="k-form-label"
              ><span class="required">*</span></kendo-label
            >
            <kendo-textbox
              formControlName="firstName"
              (keydown)="commonService.onlyAlphabateInputValidation($event)"
              #firstName
              appNoSpacesOrSpecialChars
            ></kendo-textbox>
          </kendo-formfield>

          <kendo-formfield class="mt-0 wmax-280">
            <kendo-label [for]="roleId" text="Role" labelCssClass="k-form-label"
              ><span class="required">*</span></kendo-label
            >
            <kendo-dropdownlist
              #roleId
              [data]="roles"
              [textField]="'name'"
              [valueField]="'id'"
              formControlName="roleId"
              [readonly]="isProfile"
              [ngClass]="{ 'k-dropdown-readonly': isProfile }"
            ></kendo-dropdownlist>
          </kendo-formfield>
        </div>
        <div class="d-flex flex-wrap gap-15 mb-20">
          <kendo-formfield class="mt-0 wmax-280">
            <kendo-label
              [for]="phoneNumber"
              text="Phone Number"
              labelCssClass="k-form-label"
              ><span class="required">*</span></kendo-label
            >
            <kendo-textbox
              formControlName="phoneNumber"
              #phoneNumber
              (input)="onPhoneNumberInput($event)"
            ></kendo-textbox>
            <div
              *ngIf="
                accountForm.controls['phoneNumber']?.touched &&
                accountForm.controls['phoneNumber']?.errors
              "
              class="error-message"
            >
              <span class="required">Please enter a valid phone number.</span>
            </div>
          </kendo-formfield>

          <kendo-formfield class="mt-0 wmax-280">
            <kendo-label
              [for]="emailAddress"
              text="Email"
              labelCssClass="k-form-label"
              ><span class="required">*</span></kendo-label
            >
            <kendo-textbox
              formControlName="emailAddress"
              #emailAddress
              autocomplete="off"
            ></kendo-textbox>
          </kendo-formfield>
        </div>
        <div class="d-flex flex-wrap gap-15 mb-20">
          <kendo-formfield
            class="mt-0 wmax-280"
            [ngClass]="{
              error:
                accountForm.hasError('passwordMismatch') &&
                accountForm.get('password')?.touched,
            }"
          >
            <kendo-label
              [for]="password"
              text="Password"
              labelCssClass="k-form-label"
              ><span class="required">*</span></kendo-label
            >
            <kendo-textbox
              formControlName="password"
              #password
              [type]="passwordVisible ? 'text' : 'password'"
              autocomplete="off"
            >
              <ng-template kendoTextBoxSuffixTemplate>
                <a
                  class="password-btn"
                  type="button"
                  (click)="togglePasswordVisibility()"
                >
                  <i
                    class="far"
                    [ngClass]="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"
                  ></i>
                </a>
              </ng-template>
            </kendo-textbox>
            <div
              *ngIf="
                accountForm.controls['password']?.touched &&
                accountForm.controls['password']?.errors?.strongPassword
              "
              class="error-message"
            >
              <span class="required"
                >Password must be at least 8 characters long and contain a
                number, an uppercase letter, a lowercase letter, and a special
                character.</span
              >
            </div>
          </kendo-formfield>

          <kendo-formfield
            class="mt-0 wmax-280"
            [ngClass]="{
              error:
                accountForm.hasError('passwordMismatch') &&
                accountForm.get('confirmPassword')?.touched,
            }"
          >
            <kendo-label
              [for]="confirmPassword"
              text="Confirm Password"
              labelCssClass="k-form-label"
              ><span class="required">*</span></kendo-label
            >
            <kendo-textbox
              formControlName="confirmPassword"
              #confirmPassword
              [type]="confirmPasswordVisible ? 'text' : 'password'"
            >
              <ng-template kendoTextBoxSuffixTemplate>
                <a
                  class="password-btn"
                  type="button"
                  (click)="toggleConfirmPasswordVisibility()"
                >
                  <i
                    class="far"
                    [ngClass]="
                      confirmPasswordVisible ? 'fa-eye' : 'fa-eye-slash'
                    "
                  ></i>
                </a>
              </ng-template>
            </kendo-textbox>
          </kendo-formfield>
        </div>
      </form>
    </div>
    <div class="d-flex gap-10 justify-content-end">
      <button kendoButton (click)="onCancel()" fillMode="outline">
        Cancel
      </button>
      <div
        [ngClass]="{ 'curser-not-allowd': accountForm.invalid }"
        class="display-inline flex-0"
      >
        <button
          kendoButton
          (click)="onSubmit()"
          [disabled]="accountForm.invalid"
        >
          Save
        </button>
      </div>
    </div>
  </div>
  <div kendoDialogContainer></div>
</div>
