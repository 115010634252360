<div class="q-content">
  <div class="d-flex gap-15 flex-wrap justify-content-between mb-20">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <div class="d-flex gap-15 flex-wrap justify-content-end">
      <app-common-header
        (triggerDataFromHeader)="getDataByHeaderData($event)"
        [showDateFilter]="false"
      ></app-common-header>
    </div>
  </div>
  <div class="q-content-bg grid-wrapper">
    <kendo-grid
      #grid
      [data]="gridView"
      [pageSize]="pageSize"
      [skip]="skip"
      [pageable]="
        gridView === undefined || !isPaginationAllowed
          ? false
          : {
              buttonCount: buttonCount,
              info: info,
              type: type,
              pageSizes: pageSizes,
              previousNext: previousNext,
              position: pagerposition,
            }
      "
      [sortable]="isSortingAllowed"
      [sort]="sort"
      (sortChange)="onSortChange($event)"
      (pageChange)="onPaginationChange($event)"
      filterable="menu"
      [filter]="filter"
      (filterChange)="onFilterChangeSQL($event)"
      [height]="gridHeight"
    >
      <kendo-grid-column
        *ngFor="let column of columns"
        [field]="column.field"
        [title]="column.title"
        [width]="200"
        [maxResizableWidth]="80"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-filterService="filterService"
        >
          <ng-container
            *ngIf="column.field == 'createdDate'; else defaultFilter"
          >
            <kendo-grid-date-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              format="dd/MM/yyyy"
            >
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-neq-operator></kendo-filter-neq-operator>
              <kendo-filter-gt-operator></kendo-filter-gt-operator>
              <kendo-filter-gte-operator></kendo-filter-gte-operator>
              <kendo-filter-lt-operator></kendo-filter-lt-operator>
              <kendo-filter-lte-operator></kendo-filter-lte-operator>
            </kendo-grid-date-filter-menu>
          </ng-container>

          <ng-template #defaultFilter>
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>
        </ng-template>

        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container
            *ngIf="column.field == 'createdDate'; else defaultTemplate"
          >
            <span>{{
              dataItem[column.field] | date: 'dd/MM/yyyy HH:mm:ss'
            }}</span>
          </ng-container>
          <ng-template #defaultTemplate>
            {{ dataItem[column.field] }}
          </ng-template>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
