<div class="q-content">
  <div class="mb-20">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
  </div>
  <div class="q-content-bg">
    <div class="mb-20 password-section">
      <form [formGroup]="passwordChangeForm" class="k-form k-form-md">
        <div>
          <kendo-formfield
            class="mt-0 wmax-280 mb-20"
            [ngClass]="{
              error:
                passwordChangeForm.hasError('mismatch') &&
                passwordChangeForm.get('password')?.touched,
            }"
          >
            <kendo-label
              [for]="password"
              text="Password"
              labelCssClass="k-form-label"
            >
              <span class="required">*</span>
            </kendo-label>
            <kendo-textbox
              formControlName="password"
              #password
              appNoSpacesOrSpecialChars
              [type]="passwordFieldType"
            >
              <ng-template kendoTextBoxSuffixTemplate>
                <button
                  kendoButton
                  look="clear"
                  class="password-btn"
                  type="button"
                  (click)="toggleVisibility('password')"
                >
                  <i
                    class="far"
                    [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                  ></i>
                </button>
              </ng-template>
            </kendo-textbox>
            <div
              *ngIf="
                passwordChangeForm.controls['password']?.touched &&
                passwordChangeForm.controls['password']?.errors?.[
                  'strongPassword'
                ]
              "
              class="error-message"
            >
              <span class="required"
                >Password must be at least 8 characters long and contain a
                number, an uppercase letter, a lowercase letter, and a special
                character.</span
              >
            </div>
          </kendo-formfield>
          <kendo-formfield
            class="wmax-280 mt-0 mb-20"
            [ngClass]="{
              error:
                passwordChangeForm.hasError('mismatch') &&
                passwordChangeForm.get('confirmPassword')?.touched,
            }"
          >
            <kendo-label
              [for]="confirmPassword"
              text="Confirm Password"
              labelCssClass="k-form-label"
            >
              <span class="required">*</span>
            </kendo-label>
            <div class="input-group">
              <kendo-textbox
                formControlName="confirmPassword"
                #confirmPassword
                [type]="confirmPasswordFieldType"
                appNoSpacesOrSpecialChars
              >
                <ng-template kendoTextBoxSuffixTemplate>
                  <button
                    kendoButton
                    look="clear"
                    class="password-btn"
                    type="button"
                    (click)="toggleVisibility('confirmPassword')"
                  >
                    <i
                      class="far"
                      [ngClass]="
                        showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'
                      "
                    ></i>
                  </button>
                </ng-template>
              </kendo-textbox>
            </div>
          </kendo-formfield>
        </div>
      </form>
    </div>
    <div
      class="d-flex gap-10 justify-content-end"
      [ngClass]="{ 'curser-not-allowd': passwordChangeForm.invalid }"
    >
      <button
        [disabled]="passwordChangeForm.invalid"
        kendoButton
        (click)="passwordchange()"
      >
        Change Password
      </button>
    </div>
  </div>
</div>
