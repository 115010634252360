export class GetLoginToken {
  static readonly type = '[LOGIN] Get Login Token'

  constructor(
    public userName: string,
    public password: string,
  ) {}
}

export class GetFeederAreaList {
  static readonly type = '[HEADER] Get feederAreaId By User'
}

export class GetFeederAreaListBasedOnFeederType {
  static readonly type = '[HEADER] Get feederAreaId Based On Feeder Type'
  constructor(public isDTR: boolean) {}
}

export class GetSubstationAreaList {
  static readonly type = '[HEADER] Get feederAreaId By User'
}

export class GetMenu {
  static readonly type = '[MENU] Get'
}

export class GetMenuWithColumns {
  static readonly type = '[MENU] Get Menu with columns'
}

export class SetFeederAreaId {
  static readonly type = '[HEADER] Set Feeder'

  constructor(public feederAreaId: any) {}
}

export class SetDeviceIdAll {
  static readonly type = '[HEADER] Set Device'

  constructor(public deviceIdAll: any) {}
}

export class SetDeviceId {
  static readonly type = '[HEADER] Set Device'

  constructor(public deviceId: any) {}
}
export class ResetStateData {
  static readonly type = '[HEADER] Reset State Data'
}

export class GetDeviceIdBySubstation {
  static readonly type = '[Common] Get DeviceId By Substation'

  constructor() {}
}

export class GetDeviceIdByFeeder {
  static readonly type = '[Common] Get DeviceId By Feeder'

  constructor() {}
}

export class GetDeviceIdByFeederAll {
  static readonly type = '[Common] Get DeviceId By Feeder All'

  constructor() {}
}

export class GetDeviceIdByFeederNotCommon {
  static readonly type = '[DEVICE] Get DeviceId By Feeder Not Header'

  constructor(public feederAreaId: any) {}
}

export class SetModulePermission {
  static readonly type = '[MENU] Set Module Permission '

  constructor(public moduleData: any) {}
}

export class GetNotCommonFeederAreaList {
  static readonly type = '[FEEDER] Get Not Common Feeder Area List'
}

export class SaveUserModuleGridColumn {
  static readonly type = '[Common] Save User Module Grid Column'
  constructor(public saveUserModuleGridColumnList: any) {}
}

export class GetRefreshLoginToken {
  static readonly type = '[Common] Get Refresh Login Token'
  constructor(public refreshToken: any) {}
}
