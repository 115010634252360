<div *ngIf="isAddMode">
  <div class="add-device">
    <form [formGroup]="complaintForm" class="k-form k-form-md">
      <div *ngIf="isAddMode">
        <div class="d-flex flex-wrap justify-content-between gap-20 mb-20">
          <kendo-formfield class="mt-0 wmax-260">
            <kendo-label
              [for]="ticketId"
              text="Ticket Id"
              labelCssClass="k-form-label"
              ><span class="required">*</span></kendo-label
            >
            <div class="curser-not-allowd">
              <kendo-textbox
                formControlName="ticketId"
                #ticketId
                [readonly]="true"
                class="disabled"
              ></kendo-textbox>
            </div>
          </kendo-formfield>

          <kendo-formfield class="mt-0 wmax-260">
            <kendo-label
              [for]="initiatedBy"
              text="Initiated By"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-textbox
              formControlName="initiatedBy"
              #initiatedBy
              [readonly]="true"
              class="disabled"
            ></kendo-textbox>
          </kendo-formfield>
        </div>
        <div class="d-flex justify-content-between flex-wrap gap-20 mb-20">
          <kendo-formfield class="mt-0 wmax-260">
            <kendo-label
              [for]="device"
              text="Device"
              labelCssClass="k-form-label"
              ><span class="required">*</span></kendo-label
            >
            <kendo-dropdownlist
              #device
              [data]="deviceList"
              [textField]="'name'"
              [valueField]="'id'"
              formControlName="device"
            ></kendo-dropdownlist>
          </kendo-formfield>

          <kendo-formfield class="mt-0 wmax-260">
            <kendo-label
              [for]="status"
              text="Status"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-dropdownlist
              #status
              [data]="statusList"
              [textField]="'name'"
              [valueField]="'id'"
              formControlName="status"
            ></kendo-dropdownlist>
          </kendo-formfield>
        </div>

        <kendo-editor
          formControlName="description"
          class="mb-20 editor-popup"
        ></kendo-editor>

        <div>
          <kendo-label
            text="Attachment"
            labelCssClass="k-form-label"
          ></kendo-label>

          <div class="file-upload-area mb-20 wp-100 wmx-100">
            <label for="fileInput" class="file-upload">
              <span class="upload-icon-container"
                ><i class="far fa-arrow-up-from-bracket"></i
              ></span>
              <p class="font-size-14 mb-0">
                <span class="primary-text fw-500">Choose file</span> to upload
              </p>
            </label>
            <input
              type="file"
              id="fileInput"
              #fileInput
              (change)="onSelectComplaintFile($event)"
            />
          </div>
          <div class="q-card pt-0 pb-0 image-url-conatiner wmx-100">
            <div *ngIf="complaintImageList.length">
              <div
                *ngFor="let data of complaintImageList; let i = index"
                class="image-upload-area"
              >
                <div class="d-flex align-items-center gap-15">
                  <img
                    [src]="data.base64"
                    alt="uploadImage"
                    (click)="onImageOpen(i)"
                  />
                  <div class="font-size-14">{{ data.fileName }}</div>
                </div>
                <div class="d-flex gap-10">
                  <a *ngIf="false" title="dowload">
                    <i
                      class="far fa-download text-secondary font-size-16 mt-5"
                    ></i>
                  </a>
                  <a title="delete" (click)="onDeleteComplaintImage(i)">
                    <i
                      class="far fa-circle-xmark text-secondary font-size-16 mt-5"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div [ngClass]="{ 'display-none': !isAddMode }">
  <kendo-dialog-actions class="action-button-dialog">
    <div class="d-flex gap-10 justify-content-end">
      <button kendoButton (click)="onCancel()" fillMode="outline">
        Cancel
      </button>
      <div
        [ngClass]="{ 'curser-not-allowd': complaintForm.invalid }"
        class="display-inline flex-0"
      >
        <button
          kendoButton
          (click)="onSubmit()"
          [disabled]="complaintForm.invalid"
        >
          Create Ticket
        </button>
      </div>
    </div>
  </kendo-dialog-actions>
</div>

<div class="q-content" *ngIf="!isAddMode">
  <div class="d-flex justify-content-between gap-5 align-items-center mb-20">
    <div class="d-flex gap-15 align-items-center">
      <p class="title mb-0">
        {{ this.complaintForm.controls.ticketId?.value }}
      </p>
      <span
        class="status"
        [ngClass]="{
          'status-active': this.complaintForm.get('status')?.value?.id === 1,
          'status-pending': this.complaintForm.get('status')?.value?.id === 2,
          'status-resolved': this.complaintForm.get('status')?.value?.id === 3,
        }"
        >{{ this.complaintForm.get('status')?.value?.name }}</span
      >
    </div>
    <div
      class="remove text-secondary font-size-24 cursor-pointer"
      (click)="redirectToComplaintGrid()"
    >
      <i class="far fa-xmark"></i>
    </div>
  </div>
  <div class="q-content-bg">
    <div>
      <form [formGroup]="complaintForm" class="k-form k-form-md">
        <kendo-tabstrip (tabSelect)="onTabSelect($event)">
          <kendo-tabstrip-tab
            title="Ticket Details"
            [selected]="seletedIndex === 0"
          >
            <ng-template kendoTabContent>
              <div
                [ngClass]="{
                  'tabs-content-device-with-footer': seletedIndex === 0,
                }"
              >
                <fieldset class="k-form-fieldset mt-0">
                  <div class="d-flex flex-wrap gap-20 mb-20">
                    <kendo-formfield class="mt-0 wmax-280">
                      <kendo-label
                        [for]="ticketIds"
                        text="Ticket Id"
                        labelCssClass="k-form-label"
                        ><span class="required">*</span></kendo-label
                      >
                      <kendo-textbox
                        formControlName="ticketId"
                        #ticketIds
                        [readonly]="true"
                        class="disabled"
                      ></kendo-textbox>
                    </kendo-formfield>

                    <kendo-formfield class="mt-0 wmax-280">
                      <kendo-label
                        [for]="initiatedBy"
                        text="Initiated By"
                        labelCssClass="k-form-label"
                      ></kendo-label>
                      <kendo-textbox
                        formControlName="initiatedBy"
                        #initiatedBy
                        [readonly]="true"
                        class="disabled"
                      ></kendo-textbox>
                    </kendo-formfield>
                  </div>

                  <div class="d-flex flex-wrap gap-20 mb-20">
                    <kendo-formfield class="mt-0 wmax-280">
                      <kendo-label
                        [for]="device"
                        text="Device"
                        labelCssClass="k-form-label"
                        ><span class="required">*</span></kendo-label
                      >
                      <kendo-dropdownlist
                        #device
                        [data]="deviceList"
                        [textField]="'name'"
                        [valueField]="'id'"
                        formControlName="device"
                      ></kendo-dropdownlist>
                    </kendo-formfield>

                    <kendo-formfield class="mt-0 wmax-280">
                      <kendo-label
                        [for]="status"
                        text="Status"
                        labelCssClass="k-form-label"
                      ></kendo-label>
                      <kendo-dropdownlist
                        #status
                        [data]="statusList"
                        [textField]="'name'"
                        [valueField]="'id'"
                        formControlName="status"
                      ></kendo-dropdownlist>
                    </kendo-formfield>
                  </div>

                  <kendo-editor
                    formControlName="description"
                    class="wmax-580 mb-20"
                  ></kendo-editor>

                  <div>
                    <kendo-label
                      text="Attachment"
                      labelCssClass="k-form-label"
                    ></kendo-label>
                    <div class="file-upload-area wmax-280">
                      <label for="fileInput" class="file-upload">
                        <span class="upload-icon-container"
                          ><i class="far fa-arrow-up-from-bracket"></i
                        ></span>
                        <p class="font-size-14 mb-0">
                          <span class="primary-text fw-500">Choose file</span>
                          to upload
                        </p>
                      </label>
                      <input
                        type="file"
                        id="fileInput"
                        #fileInput
                        (change)="onSelectComplaintFile($event)"
                      />
                    </div>
                    <div class="q-card pt-0 pb-0 image-url-conatiner">
                      <div *ngIf="complaintImageList.length">
                        <div
                          *ngFor="let data of complaintImageList; let i = index"
                          class="image-upload-area"
                        >
                          <div class="d-flex gap-15 align-items-center">
                            <img
                              [src]="data.base64"
                              alt="uploadImage"
                              (click)="onImageOpen(i)"
                            />
                            <div class="font-size-14">{{ data.fileName }}</div>
                          </div>
                          <div class="d-flex gap-10">
                            <a *ngIf="false" title="download">
                              <i
                                class="far fa-download text-secondary font-size-16 mt-5"
                              ></i>
                            </a>
                            <a
                              title="delete"
                              (click)="onDeleteComplaintImage(i)"
                            >
                              <i
                                class="far fa-circle-xmark text-secondary font-size-16 mt-5"
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="Comments" [selected]="seletedIndex === 1">
            <ng-template kendoTabContent>
              <div>
                <kendo-editor
                  #editor
                  formControlName="commentDescription"
                  class="mb-20 wmax-580"
                ></kendo-editor>
                <div>
                  <kendo-label
                    text="Attachment"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                  <div class="file-upload-area wmax-280">
                    <label for="CommentfileInput" class="file-upload">
                      <span class="upload-icon-container"
                        ><i class="far fa-arrow-up-from-bracket"></i
                      ></span>
                      <p class="font-size-14 mb-0">
                        <span class="primary-text fw-500">Choose file</span> to
                        upload
                      </p>
                    </label>
                    <input
                      type="file"
                      id="CommentfileInput"
                      #CommentfileInput
                      (change)="onSelectCommentFile($event)"
                    />
                  </div>
                  <div>
                    <div class="q-card pt-0 pb-0 image-url-conatiner">
                      <div *ngIf="commentComplaintImageList.length">
                        <div
                          *ngFor="
                            let data of commentComplaintImageList;
                            let i = index
                          "
                          class="image-upload-area"
                        >
                          <div class="d-flex gap-15 align-items-center">
                            <img
                              [src]="data.base64"
                              alt="uploadImage"
                              (click)="onCommentImageOpen(i)"
                            />
                            <div class="font-size-14">{{ data.fileName }}</div>
                          </div>
                          <div class="d-flex gap-10">
                            <!-- <a title="download">
                              <i
                                class="far fa-download phaseR font-size-16 mt-5"
                              ></i>
                            </a> -->
                            <a
                              title="delete"
                              (click)="removeCommentComplaintImage(i)"
                            >
                              <i
                                class="far fa-circle-xmark text-secondary font-size-16 mt-5"
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    [ngClass]="{ 'curser-not-allowd': complaintForm.invalid }"
                    class="display-inline flex-0"
                  >
                    <button (click)="onSubmitComment()" kendoButton>
                      Add Comment
                    </button>
                  </div>
                  <hr />
                  <div class="comment-view-section">
                    <div *ngFor="let comment of commentList" class="comment">
                      <div class="d-flex gap-20 flex-wrap">
                        <kendo-avatar
                          [tooltip]="comment.userName"
                          [border]="true"
                          rounded="full"
                          [initials]="getInitials(comment.userName)"
                        >
                        </kendo-avatar>
                        <div>
                          <div class="comment-details">
                            <h3
                              class="font-size-14 text-primary fw-normal mb-0"
                            >
                              {{ comment.userName }}
                            </h3>
                            <p class="font-size-14 text-secondary">
                              {{
                                comment.createdOn | date: 'MMM d, yyyy hh:mm a'
                              }}
                            </p>
                          </div>
                          <div class="comment-body">
                            <p class="font-size-14 text-secondary mb-0">
                              {{ removeParagraphTag(comment.comment) }}
                            </p>
                            <div class="q-card pt-0 pb-0 image-url-conatiner">
                              <div
                                *ngFor="
                                  let data of comment.ticketCommentImageModels;
                                  let i = index
                                "
                                class="image-upload-area border pt-10 pb-10"
                              >
                                <div class="d-flex gap-15 align-items-center">
                                  <img
                                    [src]="data.base64"
                                    (click)="
                                      onCommentListImageOpen(
                                        comment.ticketCommentImageModels,
                                        i
                                      )
                                    "
                                    alt="uploadImage"
                                  />
                                  <div
                                    class="font-size-14 d-flex gap-5 flex-column"
                                  >
                                    <span class="text-primary">{{
                                      data.fileName
                                    }}</span>
                                    <!-- <span class="text-secondary">50KB</span> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="Activity" [selected]="seletedIndex === 2">
            <ng-template kendoTabContent>
              <div class="tabs-content-device">
                <div *ngFor="let activity of activityList" class="comment">
                  <div class="d-flex gap-20 flex-wrap">
                    <kendo-avatar
                      [tooltip]="activity.historyCreatedByName"
                      [border]="true"
                      rounded="full"
                      [initials]="getInitials(activity.historyCreatedByName)"
                    >
                    </kendo-avatar>
                    <div>
                      <div class="comment-details">
                        <h3 class="font-size-14 text-primary fw-normal mb-0">
                          {{ activity.historyCreatedByName }}
                        </h3>
                        <p class="font-size-14 text-secondary">
                          {{
                            activity.historyCreatedDate
                              | date: 'MMM d, yyyy hh:mm a'
                          }}
                        </p>
                      </div>
                      <div class="comment-body">
                        <div
                          class="text-secondary font-size-14 mb-20"
                          [innerHTML]="
                            removeParagraphTag(activity.ticketDescription)
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </form>
    </div>
    <div
      *ngIf="this.seletedIndex === 0"
      class="footer-button-container d-flex gap-15"
    >
      <button kendoButton (click)="onCancel()" fillMode="outline">
        Cancel
      </button>
      <div
        [ngClass]="{ 'curser-not-allowd': complaintForm.invalid }"
        class="display-inline flex-0"
      >
        <button
          (click)="onSubmit()"
          kendoButton
          [disabled]="complaintForm.invalid"
        >
          Update Ticket
        </button>
      </div>
    </div>
  </div>
</div>
