<div class="q-content">
  <div class="d-flex justify-content-between flex-wrap gap-15 mb-20">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <app-common-header
      (triggerDataFromHeader)="getDataByHeaderData($event)"
      [showDateFilter]="false"
    ></app-common-header>
  </div>
  <div class="q-content-bg">
    <kendo-grid
      [kendoGridBinding]="gridData"
      [pageable]="false"
      [pageSize]="10"
      [sortable]="true"
      class="mb-20"
      [height]="gridHeight"
    >
      <kendo-grid-column
        field="alertType"
        title="Alert Type"
        [sortable]="true"
        [width]="250"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex align-items-center justify-content-between">
            <div>{{ dataItem.alertType }}</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="enable_disbale"
        title="Enable/Disable"
        [sortable]="true"
        [width]="250"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>
            <kendo-switch
              [(ngModel)]="dataItem.isEmailAlertEnable"
              (ngModelChange)="onEnableDisableChange($event, dataItem)"
            ></kendo-switch>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="telegram_update"
        title="Telegram Update"
        [sortable]="true"
        [width]="250"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>
            <kendo-switch
              [(ngModel)]="dataItem.isMobileAlertEnable"
              (ngModelChange)="onMobileNotifyChange($event, dataItem)"
            ></kendo-switch>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="interval_time"
        title="Interval Time (Hrs)"
        [sortable]="true"
        [width]="250"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <kendo-dropdownlist
                [data]="intervalArray"
                [value]="getIntervalObject(dataItem.alertIntervalTime)"
                [textField]="'key'"
                [valueField]="'value'"
                class="w-100"
                (valueChange)="onIntervalChange($event, dataItem)"
              ></kendo-dropdownlist>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <div class="d-flex justify-content-end">
      <div
        class="display-inline"
        [ngClass]="{
          'curser-not-allowd':
            !(hasEditPermission$ | async) && !(hasAddPermission$ | async),
        }"
      >
        <button
          kendoButton
          (click)="onSubmit()"
          [disabled]="
            !(hasEditPermission$ | async) && !(hasAddPermission$ | async)
          "
        >
          Save
        </button>
      </div>
    </div>
  </div>
  <div kendoDialogContainer></div>
</div>
