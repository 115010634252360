<div class="q-content">
  <div class="mb-20">
    <app-common-header-text
      [isUpdateDataVisible]="false"
      [isInfoVisible]="true"
      (infoClick)="chartInfoClick()"
    ></app-common-header-text>
  </div>
  <div class="q-content-bg section-height-with-header">
    <div>
      <form class="k-form k-form-md">
        <fieldset class="k-form-fieldset">
          <div class="d-flex gap-15 flex-wrap">
            <kendo-formfield class="mt-0 wmax-350">
              <kendo-label
                text="Device Location"
                labelCssClass="k-form-label"
              ></kendo-label>
              <div class="curser-not-allowd">
                <kendo-dropdownlist
                  [data]="feederAreaList"
                  [(ngModel)]="feederId"
                  [textField]="'key'"
                  [valueField]="'value'"
                  [ngModelOptions]="{ standalone: true }"
                  (selectionChange)="onFeederAreaChange($event)"
                ></kendo-dropdownlist>
              </div>
            </kendo-formfield>
            <kendo-formfield class="mt-0">
              <div class="d-flex gap-15 flex-wrap">
                <div>
                  <kendo-label
                    text="Devices"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                  <kendo-dropdownlist
                    [data]="deviceList"
                    [(ngModel)]="deviceId"
                    [textField]="'key'"
                    [valueField]="'value'"
                    [ngModelOptions]="{ standalone: true }"
                    (selectionChange)="this.isAnalysisInfoVisible = false"
                    (valueChange)="ondeviceChange()"
                    class="w-150"
                  ></kendo-dropdownlist>
                </div>
              </div>
            </kendo-formfield>
          </div>
        </fieldset>
      </form>
    </div>
    <div *ngIf="isAnalysisInfoVisible">
      <hr />
      <div class="font-size-16 text-primary fw-500 mb-20">
        DT prediction for transformer:
        <span class="status-title ml-5"
          >{{ deviceId?.value }}-{{ feederId?.value }}</span
        >
      </div>

      <div>
        <div class="d-flex gap-5 flex-wrap mb-20">
          <div class="text-secondary text-label">
            <i class="far fa-arrows-rotate font-size-14 mr-10"></i>Last updated
            on:
          </div>
          <div class="device-value">
            {{
              convertToDate(analysisInfo?.lastUpdatedDate)
                | date: 'dd/MM/yyyy HH:mm:ss'
            }}
          </div>
        </div>

        <div class="d-flex gap-5 flex-wrap mb-20">
          <div class="text-secondary text-label">
            <i class="far fa-folder-arrow-down font-size-14 mr-10"></i>ITMS
            installed on:
          </div>
          <div class="device-value">
            {{ analysisInfo?.installationDate }}
          </div>
        </div>

        <div class="d-flex gap-5 flex-wrap mb-20">
          <div class="text-secondary text-label">
            <i class="far fa-tower-broadcast font-size-14 mr-10"></i>ITMS
            Status:
          </div>
          <div class="device-value">
            {{ analysisInfo?.status }}
          </div>
        </div>

        <div class="d-flex gap-5 flex-wrap mb-20">
          <div class="text-secondary text-label">
            <i class="far fa-transformer-bolt mr-10"></i>Transformer installed
            on:
          </div>
          <div class="device-value">{{ analysisInfo?.rated_KVA }} kVA</div>
        </div>

        <div class="d-flex gap-5 flex-wrap mb-20">
          <div class="text-secondary text-label">
            <i class="far fa-bolt font-size-14 mr-10"></i>Transformer Capacity:
          </div>
          <div class="device-value">{{ analysisInfo?.rated_KVA }} kVA</div>
        </div>

        <div class="d-flex gap-5 flex-wrap mb-20">
          <div class="text-secondary text-label">
            <i class="far fa-location-dot font-size-14 mr-10"></i>Transformer
            Installed at:
          </div>
          <div class="device-value">
            {{ analysisInfo?.address }}
          </div>
        </div>
      </div>

      <div class="q-card border dtr-graph-container">
        <div class="k-loading-panel" *ngIf="showLoader">
          <div class="k-loading-panel-mask"></div>
          <div class="k-loading-panel-wrapper">
            <kendo-loader
              *ngIf="showLoader"
              type="infinite-spinner"
              size="large"
            >
            </kendo-loader>
            <div class="k-loading-panel-text">Loading...</div>
          </div>
        </div>
        <kendo-chart
          [pannable]="true"
          [zoomable]="true"
          [seriesColors]="[
            '#ed6572',
            '#f9bc43',
            '#3a82f4',
            '#2cac8d',
            '#972f97',
            '#ff55ff',
            '#c37171',
          ]"
        >
          <kendo-chart-title text="Temperature (°C)"></kendo-chart-title>
          <kendo-chart-legend
            position="bottom"
            orientation="horizontal"
          ></kendo-chart-legend>
          <kendo-chart-series>
            <kendo-chart-series-item
              *ngFor="let item of series"
              type="line"
              [data]="item.data"
              [name]="item.name"
            >
            </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
      </div>
    </div>
  </div>
</div>

<kendo-dialog
  title="Chart Info"
  *ngIf="openChartInfo"
  (close)="onCloseDialog()"
  [width]="550"
>
  <div class="mt-10">
    <div>
      <div class="d-flex gap-10">
        <i class="far fa-circle font-size-8 mt-5"></i>
        <p>
          {{ analysisInfo?.predictionMessage }}
        </p>
      </div>
      <div class="d-flex gap-10">
        <i class="far fa-circle font-size-8 mt-5"></i>
        <p>
          As Current is directly proportional to temperature of the winding, in
          our observation increase in current also increases the temperature at
          the winding on the loaded Phase. We observe the Load imbalances on
          each phase and also monitor the individual winding and oil temperature
          for the DTR prediction.
        </p>
      </div>
      <div class="d-flex gap-10">
        <i class="far fa-circle font-size-8 mt-5"></i>
        <p class="mb-0">
          However in this chart we predict, the failure of the device is based
          on the winding temperature of each phase in relation to the current in
          phase in the below model.
        </p>
      </div>
    </div>
    <hr />
    <div>
      <p class="para para-purple mb-20">
        ---- Purple line represents winding temperature, generated through input
        data sets.
      </p>

      <p class="para para-red mb-20">
        ---- Red line is the upper threshold limit which indicates limit at
        which transformer may fail(potentially). When the purple line(winding
        temperature) reaches red line(upper threshold limit) and touches it, it
        implies that the winding temperature is very high. If the purple
        line(winding temperature) doesn't comes down soon and keeps on crossing
        upper threshold limit constantly, it means eventually the transformer
        may fail.
      </p>

      <p class="para para-black mb-20">
        ---- Black line is refers to a state where there is no input to the
        transformer. when purple touches black, at that point temp is very low
        this could mean, either there is less load on transformer or transformer
        is not working
      </p>

      <p class="para para-magenta mb-0">
        ---- Magenta line is the normal temperature or mean temperature of the
        device over a period of time (an hour in this case), displays ideal
        temperature condition for functioning of transformer. Purple
        line(winding temperature) runs close to magenta line most of the time
        which can bee seen as its
      </p>
    </div>
  </div>
  <kendo-dialog-actions> </kendo-dialog-actions>
</kendo-dialog>
